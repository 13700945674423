/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkt9rZ3YwMS9mVVYgYzB2Nm7jGY9Cw6QY",
  authDomain: "simonstrangebooks.firebaseapp.com",
  projectId: "simonstrangebooks",
  storageBucket: "simonstrangebooks.appspot.com",
  messagingSenderId: "837506934815",
  appId: "1:837506934815:web:71025eeb94272b4ea44d2a",
  measurementId: "G-7SHMD2V0J9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);

const api = `https://us-central1-simonstrangebooks.cloudfunctions.net/api`

