/* eslint-disable no-unused-vars */
import React from 'react';
import './App.css';
import Home from './Views/Main/Home';
import { AppContextProvider } from './AppContext';
import { ThemeProvider } from 'styled-components';
import SSBTheme from './Components/Theme';
import { auth } from './Firebase/fsconfig'
import { useAuthState } from 'react-firebase-hooks/auth';


function App() {
  const [user] = useAuthState(auth);

  return (
    <ThemeProvider theme={SSBTheme} >
    <AppContextProvider>
      <Home />
    </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
