import { styled } from 'styled-components';

export const Root = styled.div`
    display: grid;
    grid-template-rows: min-content auto min-content;
    align-items: center;
    justify-content: flex-start;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.secondary};
    overflow-y: auto;
    height: 100vh;
    width: 100vw;
    position: relative;
`

export const Header = styled.div`
    display: grid;
    padding: 1rem;
    position: sticky;
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    justify-content: space-between;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.secondary};
    width: 100vw;
    z-index: 100;
`

export const HeaderColumn = styled(({ justify, ...restProps }) => <div {...restProps}/>)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${({justify}) => justify};
    gap: 1rem;
`

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.secondary};
    width: 100vw;
    padding: 1rem;
    border-top: 1px solid ${({theme}) => theme.colors.secondary};
`

export const Body = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: ${({theme}) => theme.colors.primary};
    color: ${({theme}) => theme.colors.secondary};
    width: 100%;
    height: 100%;
`