import {ReactComponent as MenuIcon} from "./MenuIcon.svg"
import {ReactComponent as AmazonIcon} from "./amazon.svg"
import {ReactComponent as BookIcon} from "./web-book.svg"

const Icons = {
    MenuIcon: <MenuIcon />,
    AmazonIcon: <AmazonIcon />,
    BookIcon: <BookIcon />
}

export default Icons;