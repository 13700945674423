import styled from "styled-components";
import LogoImageWhite from "../Images/logowhitetransparent.png";
import LogoImageBlack from "../Images/logoblacktransparent.png";
import { useNavigate } from "react-router-dom";

const LogoImg = styled.img`
  height: ${({ height }) =>
    height ? `${height}px` : "100px"}; // Ensure height is a string with px
  width: auto;
  transition: height 0.5s ease-in-out;
`;

export const Logo = ({ height, color }) => {
  const navigate = useNavigate();
  return <LogoImg src={color === "black" ? LogoImageBlack : LogoImageWhite} height={height} onClick={() => navigate("/")}/>;
};
