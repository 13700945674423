import { styled } from "styled-components";
import Icons from "../Icons/Icons";

const baseButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
`;

export const HeaderLink = styled(baseButton)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.2rem;
  font-weight: 400;
  transition: color 0.5s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.linkHighlight};
  }
`;

export const MobileMenuButton = styled(baseButton)`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg {
    height: 1.5rem;
    width: auto;
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;

export const MenuButton = ({onClick}) => {
  return <MobileMenuButton onClick={onClick}>{Icons.MenuIcon}</MobileMenuButton>;
};
