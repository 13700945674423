import React, { createContext, useState } from "react";
import DOBCover from "./Images/Daddys-Other-Boy-Kindle.jpg"
import CBTHTCover from "./Images/cucked-by-the-hell-twink.jpg"
import Icons from "./Icons/Icons";
import { useNavigate } from "react-router-dom";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [state, setState] = useState({});
  const [mobile, setMobile] = useState(false);
  const [logoHeight, setLogoHeight] = useState(100);
  const [menuVisible, setMenuVisible] = useState(false);
  const [splashPanels, setSplashPanels] = useState([
    {
      image: DOBCover,
      title: `Daddy's Other Boy`,
      tagline: `Deliciously naughty M/M/M cuckolding daddy kink with a HEA!`,
      link: "https://www.amazon.com/dp/B0C3F9HDMN",
      icon: Icons.AmazonIcon,
      linktype: "external",
    },
    {
      image: CBTHTCover,
      title: `Cucked by the Hell Twink`,
      tagline: `Supernatural cuckolding full of dub-con kink, too hot for Amazon!`,
      link: "/books/cucked-by-the-hell-twink",
      icon: Icons.BookIcon,
      linktype: "internal"
    },

  ])
  const [recent, setRecent  ] = useState([
    {
      image: null,
      title: "A Blog Title",
      tagline: "A Tagline",
      link: "/posts/a-post",
      linktext: "Read Post",
      posttype: "blog"
    },
    {
      image: null,
      title: "A Story Title",
      tagline: "A Tagline",
      link: "/storyes/a-story",
      linktext: "Read Story",
      posttype: "web-story"
    },
    {
      image: null,
      title: "A Story Title",
      tagline: "A Tagline",
      link: "/storyes/a-story",
      linktext: "Read Story",
      posttype: "web-story"
    },
    {
      image: null,
      title: "A Story Title",
      tagline: "A Tagline",
      link: "/storyes/a-story",
      linktext: "Read Story",
      posttype: "web-story"
    },
    {
      image: null,
      title: "A Story Title",
      tagline: "A Tagline",
      link: "/storyes/a-story",
      linktext: "Read Story",
      posttype: "web-story"
    },
  ])

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
        mobile,
        setMobile,
        logoHeight,
        setLogoHeight,
        menuVisible,
        setMenuVisible,
        splashPanels,
        recent
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
