/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../AppContext";
import {
  Body,
  Footer,
  Root,
} from "../../Components/Containers";
import SSBHeader from "./Header";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import About from "../Main/About/About"
import Contact from "../Main/Contact/Contact"
import Login from "../Main/Login/Login"
import Register from "../Main/Register/Register"
import News from "../Main/News/News"
import Books from "../Main/Books/Books"
import { Button, Card, Carousel, Stack } from "react-bootstrap";

const Home = () => {
  const { state, setState, setMobile, setLogoHeight } = useContext(AppContext);

    useEffect(() => {
        //First check the size of the window and if it is less than 768px, set the mobile state to true.
        if (window.innerWidth < 768) {
          setMobile(true);
        }

        //Set a listener to monitor the width of the window. If the width is less than 768px, set the mobile state to true. Otherwise, set it to false.
        const handleResize = () => {
          if (window.innerWidth < 768) {
            setMobile(true);
          } else {
            setMobile(false);
          }
        }
    
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        }
      }, []);

    const rootRef = useRef(null);
    //A useEffect hook to set the logo height based on the scroll position. If the scroll position is greater than 0, the logo height is set to 50px. Otherwise, it is set to 100px.
    useEffect(() => {
        const handleScroll = () => {
            if (rootRef.current) {
              const scrollTop = rootRef.current.scrollTop;
              console.log("checking scroll");
              if (scrollTop > 0) {
                setLogoHeight(50);
              } else {
                setLogoHeight(100);
              }
            }
          };
      
          if (rootRef.current) {
            rootRef.current.addEventListener("scroll", handleScroll);
          }
      
          return () => {
            if (rootRef.current) {
              rootRef.current.removeEventListener("scroll", handleScroll);
            }
          };
    }, [])

  return (
    <Router>
      <Root ref={rootRef}>
        <SSBHeader />
        <Body>
          <Routes>
            <Route exact path="/" element={<HomeComponent/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/news" element={<News/>} />
            <Route path="/books" element={<Books/>} />
            {/* Add more Routes as needed */}
          </Routes>
        </Body>
        <Footer>
          <label
            style={{
              fontSize: "0.8rem",
            }}
          >
          SimonStrangeBooks.com is © 2023 Simon Strange
          </label>
          </Footer>
      </Root>
    </Router>
  );
};

export default Home;

function HomeComponent(){
  return (
    <Stack gap={0} style={{width: "100%"}}>
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      backgroundColor: "#e7beff",
      color: "black",
    }}>
      <h4 style={{padding: "10px"}}>Recent Releases:</h4>
      <hr style={{width: "90%", margin: "0px"}}/>
      <Splash />
    </div>
    <Recent />
    <MemberPitch />
    </Stack>
  )
}

function Splash(){
  const {splashPanels} = useContext(AppContext);
  return (
    <Carousel
      controls={true}
      pause={"hover"}
      touch={true}
      variant="dark"
    >
      {
        splashPanels.map((item, index) => {
          return (
            <Carousel.Item key={index} interval={6000}>
              <SplashItem item={item}/>
            </Carousel.Item>
          )
        }
        )
      }
    </Carousel>
  )
}

const SplashItem = ({item}) => {
  const {mobile} = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: `100%`,
        maxWidth: `${mobile ? "100%" : "1000px"}`,
        padding: "20px 50px 40px",
        boxSizing: "border-box",
        minHeight: "300px",
        maxHeight: "500px",
        margin: "0px auto"
      }}
      >
        <img src={item.image} alt="DOBCover" style={{width: "30%"}}/> 
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "left",
            width: "50%",
            backgroundColor: "transparent",
            color: "black",
            gap: "10px"
          }}
        >
          <h5>{item.title}</h5>
          <label>{item.tagline}</label>
          {item.linktype === "external" ? <Button variant="secondary" target="new" href={item.link}>
            {item.icon}
            {`  `}
            Get It Now
          </Button> 
          : 
          <Button variant="danger" onClick={() => navigate(item.link)}>
            {item.icon}
            {`  `}
            Member Exclusive
          </Button> 
        }
        </div>
      </div>
  )
}

const Recent = () => {
  const navigate = useNavigate();
  const {recent, mobile } = useContext(AppContext);
  return (
    <div 
      style={{
        backgroundColor: "lightgray",
        width: "100%",
        color: "black",
      }}
    >
      <h4 style={{padding: "10px", width: "100%", margin: "0px auto", textAlign: "center"}}>Simon's Recent Posts:</h4>
      <div 
        style={{
          display: `grid`,
          alignItems: "center",
          gridTemplateColumns: `${mobile ? "1fr" : "1fr 1fr 1fr"}`,
          alignContent: "center",
          justifyContent: "left",
          flexDirection: `${mobile ? "column" : "row"}`,
          flexWrap: "wrap",
          gap: "10px",
          padding: "20px",
          maxWidth: `${mobile ? "100%" : "920px"}`,
          margin: "0px auto"
        }}
      >
      {
        recent.map((item, index) => {
          return (
            <Card style={{ 
              width: `${mobile ? "90%" : "auto"}`, 
              flexGrow: `${mobile ? "0" : "1"}`,
              boxSizing: "border-box",
              maxWidth: `${mobile ? "100%" : "300px"}`, 
              margin: `${mobile ? "0px auto" : "0px"}`
              }}>
            <Card.Img variant="top" src={item.image} />
            <Card.Body>
              <Card.Title>{item.title}</Card.Title>
              <Card.Text>
                {item.tagline}
              </Card.Text>
              <Button variant="primary" onClick={() => navigate(item.link)}>{item.linktext}</Button>
            </Card.Body>
          </Card>
          )
        }
        )
      }
      </div>
    </div>
  )
}

const MemberPitch = () => {
  const {mobile} = useContext(AppContext);
  return (
    <div 
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px 50px"
    }}>
      <h4 style={{padding: "10px"}}>Why not become one of Simon's Strangers?</h4>
      <p>
        Simon's Strangers get access to exclusive content, early releases, and more!
      </p>
      <p>
        Choose one of two tiers:
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: `${mobile ? "column" : "row"}`,
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          width: "100%",
          maxWidth: "1000px",
        }}
      >
        <Card style={{
          width: `${mobile ? "90%" : "auto"}`,
          flexGrow: `${mobile ? "0" : "1"}`,
          boxSizing: "border-box",
          maxWidth: `${mobile ? "100%" : "300px"}`, 
          margin: `${mobile ? "0px auto" : "0px"}`
          }}>
          <Card.Body>
            <Card.Title>Casual Stranger</Card.Title>
            <Card.Text>
              You'll get:
            </Card.Text>
            <ul>
              <li>News about upcoming and new releases</li>
              <li>Access to free red-label stories on the website</li>
              <li>Special pre-order pricing for electronic and hardcopy books</li>
            </ul>
            <Card.Text>
              Free!
            </Card.Text>
            <Button variant="primary">Become a Stranger</Button>
          </Card.Body>
        </Card>
        <Card style={{
          width: `${mobile ? "90%" : "auto"}`,
          flexGrow: `${mobile ? "0" : "1"}`,
          boxSizing: "border-box",
          maxWidth: `${mobile ? "100%" : "300px"}`, 
          margin: `${mobile ? "0px auto" : "0px"}`
          }}>
          <Card.Body>
            <Card.Title>Intimate Stranger</Card.Title>
            <Card.Text>
              You'll get everything a Casual Stranger gets, plus:
            </Card.Text>
            <ul>
              <li>Access to black-label stories on the website</li>
              <li>Access to exclusive bonus content like bonus art and scenes</li>
              <li>All short-story and novella releases each month</li>
            </ul>
            <Card.Text>
              $5/month
            </Card.Text>
            <Button variant="primary">Become an Intimate Stranger</Button>
          </Card.Body>
        </Card>
        <Card style={{
          width: `${mobile ? "90%" : "auto"}`,
          flexGrow: `${mobile ? "0" : "1"}`,
          boxSizing: "border-box",
          maxWidth: `${mobile ? "100%" : "300px"}`, 
          margin: `${mobile ? "0px auto" : "0px"}`
          }}>
          <Card.Body>
            <Card.Title>Favorite Stranger</Card.Title>
            <Card.Text>
              You'll get everything an Intimate Stranger gets, plus:
            </Card.Text>
            <ul>
              <li>All short story, novella, and novel length releases</li>
              <li>Beta-reading invitations</li>
              <li>Access to the Dungeon, where the darkest, kinkiest stories live</li>
            </ul>
            <Card.Text>
              $10/month
            </Card.Text>
            <Button variant="primary">Become a Favorite Stranger</Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}