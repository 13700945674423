/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import { Header, HeaderColumn } from "../../Components/Containers";
import { Logo } from "../../Components/Images";
import { HeaderLink, MenuButton } from "../../Components/Buttons";
import { AppContext } from "../../AppContext";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

const MenuBody = styled(Offcanvas.Body)`
  padding: 0px;
`;

const MenuList = styled(ButtonGroup)`
  border: none;
  border-radius: 0px;
  width: 100%;
`;

const MenuListItem = styled(Button)`
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  padding: 1rem;
  background-color: white;
  margin: 1px 0px;
  font-size: 1.2rem;
`;

const SSBHeader = () => {
  const { mobile, logoHeight, menuVisible, setMenuVisible } =
    useContext(AppContext);
  const navigate = useNavigate();

  const handleClick = (path) => {
    setMenuVisible(false);
    navigate(path);
  };

  useEffect(() => {
    console.log("Menu visible: ", menuVisible);
  }, [menuVisible]);
  return (
    <Header>
      <HeaderColumn justify="left">
        <Logo height={logoHeight} />
      </HeaderColumn>
      <HeaderColumn justify="right">
        {mobile ? (
          <MenuButton onClick={() => setMenuVisible(!menuVisible)} />
        ) : (
          <>
            <HeaderLink onClick={() => handleClick("/")}>Home</HeaderLink>
            <HeaderLink onClick={() => handleClick("about")}>About</HeaderLink>
            <HeaderLink onClick={() => handleClick("books")}>Books</HeaderLink>
            <HeaderLink onClick={() => handleClick("contact")}>
              Contact
            </HeaderLink>
            <HeaderLink onClick={() => handleClick("register")}>
              Register
            </HeaderLink>
            <HeaderLink onClick={() => handleClick("login")}>Login</HeaderLink>
          </>
        )}
      </HeaderColumn>
      <Offcanvas show={menuVisible} onHide={() => setMenuVisible(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Logo height={50} color="black" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <MenuBody>
          <MenuList vertical>
            <MenuListItem onClick={() => handleClick("/")} variant="light">
              Home
            </MenuListItem>
            <MenuListItem onClick={() => handleClick("about")} variant="light">
              About
            </MenuListItem>
            <MenuListItem onClick={() => handleClick("books")} variant="light">
              Books
            </MenuListItem>
            <MenuListItem
              onClick={() => handleClick("contact")}
              variant="light"
            >
              Contact
            </MenuListItem>
            <MenuListItem
              onClick={() => handleClick("register")}
              variant="light"
            >
              Register
            </MenuListItem>
            <MenuListItem onClick={() => handleClick("login")} variant="light">
              Login
            </MenuListItem>
          </MenuList>
        </MenuBody>
      </Offcanvas>
    </Header>
  );
};

export default SSBHeader;
